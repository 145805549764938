import React from "react"

const Contact = () => {
  return (
    <>
      <div>this is my email</div>
    </>
  )
}

export default Contact
